import { hasCoupons } from '@wix/ambassador-coupons-api-v1-coupon/http';
import {
  memberGetOrder,
  getOnlineOrderPreview,
  createOnlineOrder,
  changeStartDate,
  getPricePreview,
  applyCoupon,
  createGuestOnlineOrder,
  getGuestOnlineOrderPreview,
  setSubmission,
} from '@wix/ambassador-pricing-plans-v2-order/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getApplicationErrorCode, PurchaseLimitExceededError } from '../utils/errors';

interface CreateGuestOrderParams {
  email: string;
  captchaToken: string;
  planId: string;
  submissionId?: string;
}

export class OrdersApi {
  constructor(protected httpClient: IHttpClient) {}

  async getOrder(id: string) {
    const response = await this.httpClient.request(memberGetOrder({ id }));
    const { order } = response.data;
    return order;
  }

  public async createOrderIfNotOverLimit(planId: string) {
    await this.createOrderPreview(planId);
    return this.createOrder(planId);
  }

  public async createOrderPreview(planId: string) {
    const response = await this.httpClient.request(getOnlineOrderPreview({ planId, startDate: new Date() }));
    const { purchaseLimitExceeded, order } = response.data;
    if (purchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    return order;
  }

  public async createOrder(planId: string, submissionId?: string) {
    try {
      const response = await this.httpClient.request(
        createOnlineOrder(submissionId ? { planId, submissionId } : { planId }),
      );
      const { order } = response.data;
      return order;
    } catch (err) {
      const errorCode = getApplicationErrorCode(err);
      if (errorCode === 'purchases_limit_reached') {
        throw new PurchaseLimitExceededError();
      } else {
        throw err;
      }
    }
  }

  public async updateOrderValidFrom(orderId: string, validFrom: Date) {
    const response = await this.httpClient.request(
      changeStartDate({
        orderId,
        startDate: validFrom,
      }),
    );
    const { order } = response.data;
    return order;
  }

  public async getPricePreview(planId: string, couponCode?: string) {
    const response = await this.httpClient.request(getPricePreview(couponCode ? { planId, couponCode } : { planId }));
    const { prices } = response.data;
    return prices;
  }

  public async applyCoupon(orderId: string, couponCode: string) {
    const response = await this.httpClient.request(applyCoupon({ orderId, couponCode }));
    const { order } = response.data;
    return order;
  }

  public async setSubmission(orderId: string, submissionId: string) {
    const response = await this.httpClient.request(setSubmission({ orderId, submissionId }));
    const { order } = response.data;
    return order;
  }

  public async hasCoupons() {
    try {
      const response = await this.httpClient.request(hasCoupons({}));
      return response.data.hasCoupons;
    } catch (e) {
      // @todo: do we need to log error here?
      return false;
    }
  }

  public async createGuestOrder({ planId, email, captchaToken, submissionId }: CreateGuestOrderParams) {
    try {
      const response = await this.httpClient.request(
        createGuestOnlineOrder(
          submissionId
            ? { planId, guest: { email }, captcha: { token: captchaToken }, submissionId }
            : { planId, guest: { email }, captcha: { token: captchaToken } },
        ),
      );

      return response.data.order;
    } catch (err) {
      if (getApplicationErrorCode(err) === 'purchases_limit_reached') {
        throw new PurchaseLimitExceededError();
      } else {
        throw err;
      }
    }
  }

  public async isLimitExceededForGuest(plan: PublicPlan, email: string): Promise<boolean> {
    if (plan.maxPurchasesPerBuyer && plan.maxPurchasesPerBuyer > 0) {
      const response = await this.httpClient.request(getGuestOnlineOrderPreview({ email, planId: plan.id! }));
      return Boolean(response.data.purchaseLimitExceeded);
    }

    return false;
  }
}

export function createOrdersApi(httpClient: IHttpClient) {
  return new OrdersApi(httpClient);
}
